import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@emotion/react";
export const CardList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding-left: ${(props) => (props.nopadddingL ? "0" : "16px")};
  padding-right: 16px;
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.clr};
  border-radius: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
  border: 1px solid #e0e0e0;
  /* background: #fff; */
`;
export const Circle = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => (props.isActive ? "#3bb213" : "#e0e0e0")};
  margin: 8px;
`;
export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const NumberTag = styled.div`
  min-width: 20px;
  width: 6px;
  height: ${(props) => (props.isempty ? "25px" : "")};
  background-color: ${(props) => props.clr};
  color: ${(props) => props.bgcolor};
  padding: 5px 7px;
  border-radius: 0 8px 8px 0;
  margin-right: ${(props) => props.marginRight};
`;

export const FlexRowMargin = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;
`;
export const ActiveText = styled.p`
  color: ${(props) => (props.isActive ? "#3bb213" : "#e0e0e0")};
  font-family: TDS_regular;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
`;
export const Cards = (props) => {
  const {
    type = "",
    title = "",
    job = "",
    isempty,
    subtitle1 = "",
    subtitle2 = "",
    isNumbarTag = false,
    entrycriteria = [],
    data = [],
    active = false,
    showEditIcon = false,
    showDeleteIcon = false,
    index = "",
    deviceicon = false,
    onEditClicked = () => false,
    onDeleteClicked = () => null,
    onRuleModelOpen = () => null,
    jobPending,
    jobDone,
  } = props;

  const theme = useTheme();
  // console.log("t", theme.palette.background.table);
  return (
    <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
      {type === "1" && (
        <CardList
          clr={theme.palette.text.primary}
          bgcolor={theme.palette.background.table}
          nopadddingL={true}
        >
          <FlexRow>
            {deviceicon && (
              <img
                src={`/images/icons/printersmall.png`}
                alt={"Printer Icon"}
                style={{ marginRight: 8 }}
              />
            )}
            {isNumbarTag && (
              <NumberTag
                clr={theme.palette.text.primary}
                bgcolor={theme.palette.background.common}
                isempty={isempty ? true : false}
                marginRight={"16px"}
              >
                <Typography variant="body2">{`${
                  isempty ? "" : index + 1
                }`}</Typography>
              </NumberTag>
            )}
            <Typography variant="body2">{title}</Typography>
          </FlexRow>
          <FlexRow>
            {active && (
              <FlexRowMargin>
                <FlexRowMargin>
                  {job && (
                    <>
                      {/* <Divider
                      flexItem
                      orientation="vertical"
                      sx={{ mx: 0.5, my: 0.5 }}
                    /> */}
                      <Typography
                        variant="subtitle2"
                        style={{ color: "rgb(52, 149, 255)" }}
                      >
                        {"0 Jobs"}
                      </Typography>
                    </>
                  )}
                  {jobPending && (
                    <>
                      <Divider
                        flexItem
                        orientation="vertical"
                        sx={{ mx: 1, my: 0.5 }}
                      />
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.success.main}
                      >
                        {"0 Done Jobs"}
                      </Typography>
                    </>
                  )}
                  {jobDone && (
                    <>
                      <Divider
                        flexItem
                        orientation="vertical"
                        sx={{ mx: 1, my: 0.5 }}
                      />
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.warning.main}
                      >
                        {"0 Pending Jobs"}
                      </Typography>
                    </>
                  )}
                </FlexRowMargin>
                <Button
                  id={"Configure Button"}
                  variant="contained"
                  onClick={() => onRuleModelOpen(data)}
                  //onClick={onRuleModelOpen}
                  style={{ backgroundColor: "#66AFFF", margin: "0 16px" }}
                >
                  {"Configure"}
                </Button>
                {entrycriteria?.length > 0 ? (
                  <Tooltip title="Entry Criteria Completed">
                    <CheckCircleIcon color="success" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Entry Criteria Not Completed">
                    <ErrorIcon sx={{ color: "#ffcc00" }} />
                  </Tooltip>
                )}
                {/* <Circle isActive={false}></Circle> */}
                {/* <ActiveText isActive={false}>{"Active"}</ActiveText> */}
              </FlexRowMargin>
            )}
            {showEditIcon && (
              <IconButton onClick={() => onEditClicked(data, index)}>
                <EditOutlinedIcon style={{ color: "rgb(52, 149, 255)" }} />
              </IconButton>
            )}
            {showDeleteIcon && (
              <IconButton onClick={() => onDeleteClicked(data)}>
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            )}
          </FlexRow>
        </CardList>
      )}
      {type === "2" && (
        <CardList
          clr={theme.palette.text.primary}
          bgcolor={theme.palette.background.table}
        >
          <FlexRow>
            {deviceicon && (
              <img
                src={`/images/icons/printersmall.png`}
                alt={"Printer Icon"}
                style={{ marginRight: 8 }}
              />
            )}
            <div>
              <Typography variant="body1">{title}</Typography>

              <FlexRow>
                <Typography variant="subtitle2">{subtitle2}</Typography>

                <Circle />

                <Typography variant="subtitle2">{subtitle1}</Typography>
              </FlexRow>
            </div>
          </FlexRow>
          <FlexRow>
            <FlexRowMargin>
              {job && (
                <>
                  {/* <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ mx: 0.5, my: 0.5 }}
                  /> */}
                  <Typography
                    variant="subtitle2"
                    style={{ color: "rgb(52, 149, 255)" }}
                  >
                    {"0 Queue Configured"}
                  </Typography>
                </>
              )}
              {jobPending && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ mx: 1, my: 0.5 }}
                  />
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.success.main}
                  >
                    {"0 Done"}
                  </Typography>
                </>
              )}
              {jobDone && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ mx: 1, my: 0.5 }}
                  />
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.warning.main}
                  >
                    {"0 Pending"}
                  </Typography>
                </>
              )}
            </FlexRowMargin>
            {active && (
              <FlexRowMargin>
                <Circle isActive={false}></Circle>
                <ActiveText isActive={false}>{"Active"}</ActiveText>
              </FlexRowMargin>
            )}
            {showEditIcon && (
              <IconButton onClick={() => onEditClicked(data)}>
                <EditOutlinedIcon style={{ color: "rgb(52, 149, 255)" }} />
              </IconButton>
            )}
            {showDeleteIcon && (
              <IconButton onClick={() => onDeleteClicked(data)}>
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            )}
          </FlexRow>
        </CardList>
      )}
    </Grid>
  );
};
