import React from "react";
import { NetworkCall } from "./networkcall";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  refreshCacheAndReload,
  semverGreaterThan,
} from "./utils";
import { ProjectInfoContext } from "./contexts";
import axios from "axios";
import { withAllContexts } from "./HOCs";
class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      call: false,
      isRender: false,
    };
  }

  componentDidMount() {
    // window.location.reload(false);

    this.checkForLatestBuild();
    this.refreshAPI();
  }

  alertSnackBar = (severity, msg) => {
    debugger;
    this.props.alert.setSnack({
      open: true,
      msg,
      severity,
    });
  };

  refreshAPI = async () => {
    // console.log(urlParams?.get("token"));
    /* window.onbeforeunload = function (e) {
      window.onunload = function () {
        window.localStorage.isMySessionActive = "false";
      };
      return undefined;
    };

    window.onload = function () {
      window.localStorage.isMySessionActive = "true";
    }; */

    let urlParams = new URLSearchParams(window.location.search);
    // let metadataId = urlParams?.get("metadata_id");
    // let authtoken = urlParams?.get("token");
    let isMicroFrontEnd = urlParams?.get("ismicrofrontend") ?? false;
    let theme = urlParams?.get("theme") ?? "default";
    // sessionStorage.setItem("MetaDataId", metadataId);
    // sessionStorage.setItem("AuthToken", authtoken);
    sessionStorage.setItem("isMicroFrontEnd", isMicroFrontEnd);
    sessionStorage.setItem("theme", theme);

    // if (metadataId && authtoken) {
    // this.getProjectDetails();
    //   } else {
    //     this.setState({
    //       ...this.state,
    //       isRender: true,
    //     });
    //     window.location.replace(process.env.REACT_APP_QDM_ADMIN_ENDPOINT);
    //   }
  };

  getProjectDetails = async () => {
    try {
      let params = {
        db_name: `${process.env.REACT_APP_DB_Name}`,
        entity: "projectvstools",
        filter: `projectvstools.projectid=='${process.env.REACT_APP_PROJECT_ID}' && projectvstools.activestatus==true`,
        return_fields: `MERGE(UNSET(projectvstools,'createdby','createddate','updatedby','updatedate'),{clientname:first(for client in clients filter client._id==projectvstools.clientid return client.clientname)},{projectname:first(for project in projects filter project._id==projectvstools.projectid return project.projectname )})`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          if (res?.data?.Code === 201) {
            let data = res.data.result[0];
            sessionStorage.setItem("ProjectDbname", btoa(data.dbname));
            sessionStorage.setItem("clientId", btoa(data.clientid));
            sessionStorage.setItem("clientname", btoa(data.clientname));
            sessionStorage.setItem("projectname", btoa(data.projectname));
            sessionStorage.setItem("projectId", btoa(data.projectid));
            this.setState({
              ...this.state,
              project: data,
              call: true,
            });
            this.getEntityDesignermetaId(data.projectid);
          } else {
            this.alertSnackBar("error", "Something Went Wrong");
          }

          // console.log("data", data);
        })
        .catch((err) => {
          console.log(err);
          // alert("Something Went Wrong");
        });
    } catch (error) {
      console.log(error);
    }
  };

  getEntityDesignermetaId = async (projectid) => {
    try {
      let params = {
        db_name: `${process.env.REACT_APP_DB_Name}`,
        entity: "projectvstools",
        filter: `DOCUMENT(projectvstools.toolid).toolid=='88fd87fa-6163-4a05-ba19-5dee347e0f2d' and projectvstools.projectid =='${projectid}'`,
        return_fields: "projectvstools.metadataid",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          debugger;
          // console.log(res.data.result[0]);
          if (res?.data?.Code === 201) {
            sessionStorage.setItem("metaId", btoa(res.data.result[0]));
          } else {
            this.alertSnackBar("error", "Something Went Wrong");
          }
        })
        .catch((err) => {
          this.alertSnackBar("error", "Something Went Wrong");
        });
    } catch (error) {}
  };

  checkForLatestBuild = () => {
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        const isVersion = semverGreaterThan(
          _.data.version,
          localStorage.getItem(LocalStorageKeys.version)
        );
        localStorage.setItem(LocalStorageKeys.version, _.data.version);
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  render() {
    let { isRender } = this.state;

    return (
      <ProjectInfoContext.Provider value={{ ...this.state }}>
        {isRender ? <></> : this.props.children}
      </ProjectInfoContext.Provider>
    );
  }
}

export default withAllContexts(AppAuth);
