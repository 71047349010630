import React from "react";
import TextField from "@mui/material/TextField";
import { Grid, Box, Typography } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import { IsRequired } from "../../components";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: "100%",
    // borderColor: theme.palette.border.textField,
    marginTop: "8px",
    borderRadius: "5px",
    padding: "8.5px 14px",
    resize: "vertical",
    color: theme.palette.text.primary,
    background: theme.palette.background.table,
  },
}));

export function QueueDetails(props) {
  const classes = useStyles();
  const onChangeState = (key, value) => {
    props.updateState(key, value);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "8px 16px",
        }}
      >
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">Entry Criteria</Typography>
          <Button
            id={"Configure Button"}
            variant="contained"
            disabled={!props.isEdit}
            onClick={handleOpen}
            style={{ backgroundColor: "#66AFFF", margin: "0 16px" }}
          >
            {"Configure"}
          </Button>
          {props?.state?.queueentrycriteria?.length > 0 ? (
            <Tooltip title="Entry Criteria Completed">
              <CheckCircleIcon color="success" />
            </Tooltip>
          ) : (
            <Tooltip title="Entry Criteria Not Completed">
              <ErrorIcon sx={{ color: "#ffcc00" }} />
            </Tooltip>
          )}
        </div> */}
      </Box>

      <div
        style={{
          padding: "16px",
          height: "calc(100% - 64px)",
          overflow: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body2" color="GrayText">
              {"Queue Name"} <IsRequired />
            </Typography>
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              size="small"
              name="queuename"
              value={props?.state?.queuename}
              onChange={(e) => onChangeState("queuename", e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="GrayText">
              Queue Id
            </Typography>
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              size="small"
              name="queueid"
              disabled
              value={props?.state?.queueid}
              onChange={(e) => onChangeState("queueid", e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="GrayText">
              Queue Group
            </Typography>
            <TextField
              id="outlined-name"
              name="msgqueuegroup"
              value={props?.state?.queuegroup}
              fullWidth
              size="small"
              margin="dense"
              onChange={(e) => onChangeState("queuegroup", e.target.value)}
            />
          </Grid>
          {/* <Grid item xs={3}>
            <Typography variant="body2" color="GrayText">
              {"IDM Id"} <IsRequired />
            </Typography>
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              size="small"

              name="idmid"
              value={props?.state?.idmid}
              onChange={(e) => onChangeState("idmid", e.target.value)}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="body2" color="GrayText">
              {"Description"}
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              placeholder="Type Here"
              className={classes.textArea}
              minRows={5}
              value={props?.state?.queuedescription}
              onChange={(e) =>
                onChangeState("queuedescription", e.target.value)
              }
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
