import React from "react";
import { Home } from "./home";
import { withNavBars, withAllContexts, withSideBars } from "./../../HOCs";
class HomeParent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <Home />
      </>
    );
  }
}

export default withNavBars(withSideBars(HomeParent));
