import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import { MeetingRoom } from '@mui/icons-material';
import { LocalStorageKeys } from "../../../utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItem,
  ListItemText,
  Box,
  Divider,
} from "@mui/material";
// import { SideNavBar } from '..';
import Popover from "@mui/material/Popover";
// import Button from '@mui/material/Button';
import LogoutIcon from "@mui/icons-material/Logout";
import { Databaseicon, ProjectName } from "../../../assets";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { ProjectInfoContext } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.appBar.main,
  },
  title: {
    display: "block",
  },
  titleContainer: {
    marginLeft: theme.spacing(2),
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
  profilePic: {
    marginLeft: "8px",
  },
}));

export const TopNavBar = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const projectinfo = React.useContext(ProjectInfoContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
    //window.location.replace(process.env.REACT_APP_QDM_ADMIN_ENDPOINT);
    // window.location.replace("/");
  };

  React.useEffect(() => {
    if (projectinfo?.call) {
      setState({
        ...state,
        dbname: atob(sessionStorage.getItem("ProjectDbname")),
        clientname: atob(sessionStorage.getItem("clientname")),
        projectname: atob(sessionStorage.getItem("projectname")),
      });
      console.log(atob(sessionStorage.getItem("projectname")));
    }

    //eslint-disable-next-line
  }, [projectinfo?.call]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <PersonOutlineOutlinedIcon style={{ fontSize: "1.3em" }} />
              <Typography sx={{ ml: "3px" }} variant="body2" noWrap>
                {/* {`${projectinfo?.project?.clientname ?? ""}`} */}
                {state?.clientname ?? "Ainqa"}
              </Typography>
            </Box>

            <Divider
              orientation="vertical"
              sx={{ height: "20px", margin: "0 8px", borderColor: "#ffffff" }}
            />
            <Box style={{ display: "flex", alignItems: "center" }}>
              <ProjectName />
              {/* <SourceOutlinedIcon style={{ fontSize: "1.3em" }} /> */}
              <Typography sx={{ ml: "4px" }} variant="body2" noWrap>
                {/* {`${projectinfo?.project?.projectname ?? ""}`} */}
                {state?.projectname ?? "Primary Care"}
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              sx={{ height: "20px", margin: "0 8px", borderColor: "#ffffff" }}
            />
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Databaseicon />
              <Typography sx={{ ml: "4px" }} variant="body2" noWrap>
                {/* {`${projectinfo?.project?.dbname ?? ""}`} */}
                {state?.dbname ?? "primary"}
              </Typography>
            </Box>
          </>
          <div className={classes.grow} />

          <div className={classes.userProfilecard}>
            <Typography variant="subtitle2">
              {`v ${localStorage.getItem("version")}`}
            </Typography>
            <hr style={{ height: 25, margin: "0px 8px" }} />

            <div className={classes.titleContainer}>
              <Typography className={classes.title} variant="body2" noWrap>
                {/* userName */}
                Martha Richards
              </Typography>
              <Typography className={classes.title} variant="caption" noWrap>
                {/* userName */}
                Pharmacist, Apollo hospital
              </Typography>
            </div>

            <Avatar className={classes.profilePic} variant="rounded">
              M
            </Avatar>
          </div>
          <IconButton onClick={handleClick}>
            <ExpandMoreIcon style={{ color: "white" }} />
          </IconButton>

          {/*  <Drawer
                        open={state.openSideNavBar}
                        variant={"temporary"}
                        anchor="left"
                        onClose={toogleSideNavBar}>
                        <div style={{ width: 240 }}>
                            <SideNavBar isMobile={true} />
                        </div>
                    </Drawer> */}
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon sx={{ width: "30px" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
