import React from "react";
import { withNavBars, withSideBars } from "./../../HOCs";
import { MessageConfiguration } from "./messageConfiguration";
class MessageConfigParent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <MessageConfiguration />
      </>
    );
  }
}

export default withNavBars(withSideBars(MessageConfigParent));
