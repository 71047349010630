import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { SideBar } from "../components";

import { sidebarlist } from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    width: "100%",
    height: "100%",
    // overflow: "auto",
    background: "#F2F3F6",
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: 56
    // },
    //[theme.breakpoints.down('md')]: {
    // paddingLeft: 0
    // ,
    //}
  },
  // topNavbar: {
  //},
  // sideNavbar: {
  // [theme.breakpoints.down('md')]: {
  // display: "none"
  //}
  //}
}));

const withNavBars = (Component) => (props) => {
  const classes = useStyles({ props });
  const [state, setState] = React.useState({
    metadataId: "",
    token: "",
  });
  // var isMicroFrontEnd = sessionStorage.getItem("isMicroFrontEnd");

  React.useEffect(() => {
    setState({
      ...state,
      metadataId: sessionStorage.getItem("MetaDataId"),
      token: sessionStorage.getItem("AuthToken"),
      ismicrofrontend: sessionStorage.getItem("isMicroFrontEnd"),
    });
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {/* Your nav bars here */}
      <div style={{ display: "flex", height: "100%" }}>
        <SideBar sidebarlist={sidebarlist} state={state} />

        {/* Content */}

        <div className={classes.content}>
          <Component state={state} {...props}>
            {props.children}
          </Component>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withNavBars;

//For sidebar
// <div className={classes.sideNavbar}>
//   <SideNavBar />
// </div>
