import {
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
  Grid,
  TextareaAutosize,
} from "@mui/material";

import { Cards } from "../../components";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
// import { AddEditState } from './addEditState';

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 16px",
    // background: theme.palette.background.tableHeader,
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.tableHeader,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "54px",
    padding: "0 16px",
    background: theme.palette.background.tableHeader,
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 114px)",
    overflowY: "auto",
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 16px",
    background: theme.palette.background.tableHeader,
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
  },
  divScroll: {
    padding: "0px 24px",
    overflow: "scroll",
    height: "calc(500px - 110px)",
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    background: theme.palette.background.table,
    overflow: "hidden",
  },
  textArea: {
    width: "100%",
    // borderColor: theme.palette.border.textField,
    marginTop: "8px",
    borderRadius: "5px",
    padding: "8.5px 14px",
    resize: "vertical",
    color: theme.palette.text.primary,
    background: theme.palette.background.table,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
};

export const InputSchema = (props) => {
  const classes = useStyles();
  const alertMessage = React.useContext(AlertContext);
  const [stateData, setStatedata] = React.useState([]);

  React.useEffect(() => {
    if (props?.state?.inputschema.length > 0) {
      setStatedata(props?.state?.inputschema);
    } else {
      setStatedata([]);
    }
  }, [props]);

  const [state, setState] = React.useState({
    input: "",
    inputschema: "",
    isEdit: false,
    open: false,
  });
  const [error, setError] = React.useState({
    isinput: false,
    isinputSchema: false,
  });

  const handleOpen = () => {
    setState({ ...state, open: true, isEdit: false });
  };

  /* for model close */
  const handleClose = () =>
    setState({
      ...state,
      input: "",
      inputschema: "",
      isEdit: false,
      open: false,
    });

  /* on Edit Button Clicked */
  const onEditClicked = (data, index) => {
    debugger;
    setState({
      ...state,
      open: true,
      isEdit: true,
      input: data.input,
      inputschema: data.inputschema,
      id: index,
    });
  };

  const onChangeState = (key, value) => {
    setState({ ...state, [key]: value });
    // props.updateState("inputschema", deleteItem)
  };

  const onSaveClicked = () => {
    let isvalidateinput = state?.input?.trim().length === 0 ? false : true;
    let isvalidateschema =
      state?.inputschema?.trim().length === 0 ? false : true;
    let isvalidschema = validSchema(state?.inputschema);
    let isentity = "undefined";
    if (isvalidschema === true || isvalidschema[0] === true) {
      let jsonData =
        isvalidschema?.length > 0
          ? JSON.parse(isvalidschema[1]?.inputschema)
          : JSON.parse(state?.inputschema);
      jsonData.forEach((v) => {
        isentity = Object.keys(v).includes("entity");
        // console.log(isentity);
      });
    }
    if (isvalidateinput && isvalidateschema && isvalidschema && isentity) {
      let data = isvalidschema?.length > 0 ? isvalidschema[1] : state;
      setStatedata([...stateData, data]);
      props.updateState("inputschema", [...stateData, data]);
      setError({
        ...error,
        isinput: state?.input?.trim().length === 0 ? true : false,
        isinputSchema: state?.inputschema?.trim().length === 0 ? true : false,
        // isSchema: validatSchema()
      });
      handleClose();
    } else {
      if (isvalidschema === false && isvalidateschema === true) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Enter Vaild Schema",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      if (isentity === false && isvalidateschema === true) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Entity is missing in Schema",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      setError({
        ...error,
        isinput: state?.input?.trim().length === 0 ? true : false,
        isinputSchema: state?.inputschema?.trim().length === 0 ? true : false,
      });
    }
  };

  const validSchema = (val, isUpdate) => {
    debugger;
    try {
      let schema = JSON.parse(val);
      if (Array.isArray(schema)) {
        if (schema.length > 0) {
          let emptyObj = schema.map((l) =>
            Object.keys(l).length === 0 ? false : true
          );
          return !emptyObj.includes(false);
        } else {
          return false;
        }
      } else if (typeof schema === "object") {
        if (schema !== null && Object.keys(schema)?.length > 0) {
          let objArray = [];
          objArray.push(schema);

          let arrayObj = {
            ...state,
            inputschema: JSON.stringify(objArray),
          };
          return [true, arrayObj];
        } else {
          return false;
        }
      }
    } catch (error) {
      return false;
    }
  };

  const onUpdateClicked = async (index) => {
    // debugger;
    let isvalidateinput = state?.input?.trim().length === 0 ? false : true;
    let isvalidateschema =
      state?.inputschema?.trim().length === 0 ? false : true;
    let isvalidschema = validSchema(state?.inputschema, true);
    let isentity = "undefined";
    if (isvalidschema === true || isvalidschema[0] === true) {
      // let jsonData = JSON.parse(state?.inputschema);
      let jsonData =
        isvalidschema?.length > 0
          ? JSON.parse(isvalidschema[1]?.inputschema)
          : JSON.parse(state?.inputschema);
      jsonData.forEach((v) => {
        isentity = Object.keys(v).includes("entity");
        // console.log(isentity);
      });
    }
    if (isvalidateinput && isvalidateschema && isvalidschema && isentity) {
      const editList = stateData.map((item, i) =>
        index === i
          ? isvalidschema?.length > 0
            ? isvalidschema[1]
            : state
          : item
      );
      props.updateState("inputschema", editList);
      setError({
        ...error,
        isinput: state?.input?.trim().length === 0 ? true : false,
        isinputSchema: state?.inputschema?.trim().length === 0 ? true : false,
        // isSchema: validatSchema()
      });
      setStatedata(editList);
      handleClose();
    } else {
      if (isvalidschema === false && isvalidateschema === true) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Enter Vaild Schema",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      if (isentity === false && isvalidateschema === true) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Entity is missing in Schema",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      setError({
        ...error,
        isinput: state?.input?.trim().length === 0 ? true : false,
        isinputSchema: state?.inputschema?.trim().length === 0 ? true : false,
        // isSchema: validatSchema()
      });
    }
  };

  return (
    <>
      <React.Fragment>
        {stateData?.length === 0 ? (
          <Box className={classes.Header}>
            <Button
              id={"Add Schema"}
              onClick={() => handleOpen()}
              variant="text"
              color="info"
              className={classes.Button}
            >
              {"+ Add Schema"}
            </Button>
          </Box>
        ) : (
          <Grid container style={{ padding: 16 }} spacing={1}>
            {stateData?.map((list, index) => (
              <Cards
                type="1"
                isNumbarTag
                index={index}
                isempty
                title={list?.input ?? ""}
                data={list}
                active={false}
                showEditIcon
                onEditClicked={onEditClicked}
                showDeleteIcon={false}
              />
            ))}
          </Grid>
        )}
      </React.Fragment>

      {/* Model */}
      <Modal
        open={state.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.style}>
          <Box className={classes.Header2}>
            <Typography variant="body1">
              {state.isEdit ? "Update Schema" : "Add Schema"}
            </Typography>
          </Box>
          <Divider />
          <div className={classes.divScroll}>
            <Grid container direction="row">
              <Grid item xs={12} sx={{ p: 2 }}>
                <Typography variant="body2" color="GrayText">
                  {" "}
                  Input Name{" "}
                </Typography>
                <TextField
                  margin="dense"
                  value={state?.input}
                  onChange={(e) => onChangeState("input", e.target.value)}
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={error?.isinput}
                  helperText={error?.isinput ? "This Field is Required*" : ""}
                />
              </Grid>
              <Grid item xs={12} sx={{ p: 2 }}>
                <Typography variant="body2" color="GrayText">
                  {" "}
                  Schema{" "}
                </Typography>
                {error?.isinputSchema ? (
                  <Typography
                    sx={{ ml: 2, padding: 0 }}
                    variant="caption"
                    color="error"
                  >
                    This Field is Required*
                  </Typography>
                ) : (
                  ""
                )}
                <TextareaAutosize
                  aria-label="minimum height"
                  value={state?.inputschema}
                  onChange={(e) => onChangeState("inputschema", e.target.value)}
                  placeholder="Type Here"
                  className={classes.textArea}
                  minRows={10}
                  // error={error?.isinputSchema}
                  // helperText={error?.isinputSchema ? "This Field is Required*" : ""}
                />
              </Grid>
            </Grid>
          </div>

          <Divider />
          <div className={classes.wrapperdiv}>
            <Button
              id={"Cancel Button"}
              onClick={() => handleClose()}
              variant="outlined"
            >
              {"Cancel"}
            </Button>
            {state.isEdit ? (
              <Button
                id={"Update Button"}
                onClick={() => onUpdateClicked(state?.id)}
                variant="contained"
                sx={{ ml: "16px" }}
                className={classes.Button}
              >
                {"Update"}
              </Button>
            ) : (
              <Button
                id={"Add Button"}
                onClick={() => onSaveClicked()}
                variant="contained"
                sx={{ ml: "16px" }}
                className={classes.Button}
              >
                {"Add"}
              </Button>
            )}
          </div>
        </Box>
        {/* <AddEditState handleClose={handleClose} updateStatedata={updateStatedata} /> */}
      </Modal>
    </>
  );
};
