/**
 * @author Gayathri Murugesan
 * @email gayathri.murugesan@ainqa.com
 * @create date 2022-04-01
 * @modify date 2022-04-01
 * @desc This File has all the navigation routes and it's rendering components will bes here.
 */

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";
import {
  AddEditPrinterQueue,
  HomeParent,
  MessageConfiguration,
  PrinterQueueHome,
} from "./../screens";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Home Routes */}
        <Route exact path={Routes.home}>
          <HomeParent />
        </Route>

        <Route exact path={Routes.printerQueue}>
          <PrinterQueueHome />
        </Route>

        <Route exact path={Routes.messageConfig}>
          <MessageConfiguration />
        </Route>

        <Route exact path={Routes.addPrinterQueue}>
          <AddEditPrinterQueue />
        </Route>

        <Route exact path={Routes.editPrinterQueue}>
          <AddEditPrinterQueue isEdit={true} />
        </Route>
      </Switch>
    </Router>
  );
};

export default RouterApp;
