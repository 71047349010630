import React from "react";
import { PrinterQueueConfig } from "./printerQueueConfig";
import { withNavBars, withAllContexts, withSideBars } from "./../../HOCs";
class PrinterQueueHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    console.log(this.props);
    return (
      <>
        <PrinterQueueConfig state={this.props.state} />
      </>
    );
  }
}

export default withNavBars(withSideBars(withAllContexts(PrinterQueueHome)));
