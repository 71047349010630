import React from "react";
import { Button, Typography, Box, Divider, Grid, Modal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Cards } from "../../components";
import {
  AlertContext,
  DialogContext,
  DrawerContext,
  ProjectInfoContext,
} from "../../contexts";
import { AlertProps, DrawerProps } from "../../utils";
import { AddEditDevice } from "./addEditDevice";
import axios from "axios";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    // textAlign: "center",
    // marginTop: "20%"
    width: "100%",
    height: "100%",
    background: theme.palette.background.common,
  },
  title: {
    display: "block",
    marginLeft: theme.spacing(1),
  },
  title1: {
    display: "block",
    marginLeft: "10px",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: "1px solid #e0e0e0",
    borderTop: "1px solid #e0e0e0",
    background: theme.palette.background.table,
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.background.table,
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    border: "1px solid #e0e0e0",
  },
  styledDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  container: {
    display: "flex",
  },
  content: {
    height: "calc(100% - 54px)",
    padding: theme.spacing(3),
    overflowX: "auto",
    background: theme.palette.background.common,
  },
  paper: {
    height: "100%",
    padding: "16px",
    overflowY: "scroll",
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
}));

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "90%",
//   height: "80%",
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   borderRadius: 3,
// };

export const Home = (props) => {
  const classes = useStyles();
  const drawercontext = React.useContext(DrawerContext);
  const dialogContext = React.useContext(DialogContext);
  const alertMessage = React.useContext(AlertContext);
  const [data, setData] = React.useState([]);
  // const [isdisable, SetDiasbled] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const projectinfo = React.useContext(ProjectInfoContext);
  const projectdbname = sessionStorage.getItem("ProjectDbname");

  React.useEffect(() => {
    debugger;
    // console.log(projectinfo?.call);
    // if (projectinfo?.call) {
    giveMeDevice();
    // }
    // eslint-disable-next-line
  }, [projectinfo?.call]);

  const giveMeDevice = async () => {
    try {
      let params = {
        db_name: `${process.env.REACT_APP_DB}`,
        entity: "printer_configuration",
        filter: "printer_configuration.activestatus==true",
        return_fields: "printer_configuration",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        debugger;
        if (res?.data?.Code === 201) {
          let data = res?.data?.result;
          setData(data);
        } else {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onAddDeviceClick = () => {
    drawercontext.setDrawer({
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddEditDevice
          // editData={null}
          giveMeDevice={giveMeDevice}
          onDrawerClose={onDrawerClose}
        />
      ),
      onClose: () => onDrawerClose(),
    });
  };

  const onDrawerClose = () => {
    drawercontext.setDrawer({
      open: false,
      direction: DrawerProps.direction.right,
      component: <></>,
    });
  };

  const onEditClicked = (data) => {
    drawercontext.setDrawer({
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddEditDevice
          isEdit
          editData={data}
          giveMeDevice={giveMeDevice}
          onDrawerClose={onDrawerClose}
        />
      ),
      onClose: () => onDrawerClose(),
    });
  };

  const onDeleteClicked = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to remove this device?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => confirmDelete(data._key),
    });
  };

  const confirmDelete = (key) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    softDelete(key);
  };

  const softDelete = async (_key) => {
    try {
      let params = {
        db_name: `${process.env.REACT_APP_DB}`,
        entity: "printer_configuration",
        filter: `printer_configuration._key == '${_key}'`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Device Removed Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        giveMeDevice();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.root}>
      {/* <Divider /> */}
      <Box className={classes.Header}>
        <Typography variant="body1" component="h5">
          Printer Configuration
        </Typography>
        {/* <Button
          id={"Message Configuration"}
          onClick={handleOpen}
          variant="outlined"
          color="primary"
          disabled={isdisable}
          startIcon={<SettingsOutlinedIcon />}
          style={{ background: "#ECF0F7" }}
        >
          {"Message Configuration"}
        </Button> */}
      </Box>
      <div className={classes.content}>
        <Box className={classes.Header2}>
          <Typography>Add a Printer</Typography>
          <Button
            variant="contained"
            className={classes.Button}
            color="info"
            onClick={onAddDeviceClick}
          >
            + Add device
          </Button>
        </Box>
        <Grid container sx={{ padding: "16px 0px" }} spacing={2}>
          {data?.map((list, index) => (
            <Cards
              type="2"
              title={list?.printername ?? ""}
              subtitle1={list?.locationoftheprinter?.longdesc ?? ""}
              subtitle2={list?.ipaddress ?? ""}
              index={index}
              data={list}
              showEditIcon
              showDeleteIcon
              deviceicon
              // job
              // jobPending
              // jobDone
              onEditClicked={onEditClicked}
              onDeleteClicked={onDeleteClicked}
            />
          ))}
        </Grid>
      </div>
      {/* <div className={classes.container}>

      </div>
       */}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MessageConfiguration
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        </Box>
      </Modal> */}
    </div>
  );
};

/* const lists = [
  {
    printerid: "",
    printername: "Konika G-3000",
    modeltype: "inkjet printers",
    color: "Yes",
    locationoftheprinter: "",
    ipaddress: "",
    port: "",
    permission: "",
    status: true,
  },
  {
    printerid: "",
    printername: "Canon Terax",
    Printertype: "",
    nickname: "",
    locationoftheprinter: "",
    ipaddress: "",
    ports: "",
    permission: "",
    status: true,
  },
]; */
