import React from "react";
import { AddEditPrinterQueue } from "./addEditPrinterQueue";
import { withNavBars, withSideBars } from "./../../HOCs";
class AddEditPrinterQueueParent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <AddEditPrinterQueue {...this.props} />
      </>
    );
  }
}

export default withNavBars(withSideBars(AddEditPrinterQueueParent));
