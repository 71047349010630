import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  drawer: {
    height: "100%",
    width: "260px",
    borderRight: "1px solid #e0e0e0",
    borderTop: "1px solid #e0e0e0",
    background: theme.palette.background.table,
  },
  drawerContainer: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    overflow: "hidden",
  },
}));

const MyTab = styled(Tab)(({ theme }) => ({
  alignItems: "flex-start",
  transition: "all .4s ease-out",
  color: `${theme.palette.text.primary}`,
  "&.Mui-selected": {
    backgroundColor: `${theme.sideBar.background.active}`,
    borderRadius: "4px",
    color: `${theme.sideBar.text.active}`,
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const SideBar = (props) => {
  const classes = useStyles(props);

  const history = useHistory();

  const SelectedTab = () => {
    let id = "";
    if (props?.sidebarlist) {
      props?.sidebarlist.map((l) => {
        if (window.location.pathname === l.path) {
          id = l.id;
        }
        return l;
      });
      return id;
    }
  };

  return (
    <div>
      <Paper className={classes.drawer} square>
        <div className={classes.drawerContainer}>
          <Tabs
            orientation={"vertical"}
            variant="scrollable"
            value={SelectedTab()}
            selectionFollowsFocus
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            {props?.sidebarlist &&
              props?.sidebarlist.map((navBar, index) => (
                <MyTab
                  id={navBar?.id}
                  key={navBar?.id}
                  value={navBar?.id}
                  label={navBar?.name}
                  {...a11yProps(navBar?.id)}
                  onClick={() =>
                    history.push({
                      pathname: navBar?.path,
                      search: `ismicrofrontend=${props?.state?.ismicrofrontend}`,
                    })
                  }
                />
              ))}
          </Tabs>
        </div>
      </Paper>
    </div>
  );
};
