import React from "react";
import { IconButton, Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    // textAlign: "center",
    // marginTop: "20%"
    // height: "Calc(100vh - 64px)",
    // height: "100%",
  },
  title: {
    display: "block",
    marginLeft: theme.spacing(1),
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // borderBottom: "1px solid #e0e0e0",
  },
  styledDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    height: "Calc(100vh - 116px)",
    padding: theme.spacing(3),
  },
}));

export const SubHeader = (props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Header}>
        <div className={classes.container}>
          <IconButton onClick={props.onBackClicked}>
            <ArrowBackIcon
              style={{
                width: "30px",
                height: "30px",
                boxShadow: "0 0 2px rgb(184 184 184)",
                borderRadius: "50%",
                padding: "4px",
              }}
            />
            {/* <ArrowCircleLeftOutlinedIcon /> */}
          </IconButton>
          <Typography className={classes.title} variant="body1" noWrap>
            {props?.title}
          </Typography>
        </div>

        <div className={classes.container}>
          <Button
            variant="outlined"
            color="primary"
            onClick={props?.onBackClicked}
          >
            {props?.button1}
          </Button>
          {props?.edit ? (
            <Button
              onClick={() => props?.onUpdateClicked()}
              variant="contained"
              color="secondary"
              style={{ marginLeft: "16px" }}
            >
              {props?.button3}
            </Button>
          ) : (
            <Button
              onClick={() => props?.onSaveClicked()}
              variant="contained"
              color="secondary"
              style={{ marginLeft: "16px" }}
            >
              {props?.button2}
            </Button>
          )}
        </div>
      </Box>
    </>
  );
};
