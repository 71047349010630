import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TopNavBar } from "../components";
import { ThemeContext } from "../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
  },
  content: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    background: "#F2F3F6",
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: 56
    // },
    //[theme.breakpoints.down('md')]: {
    // paddingLeft: 0
    // ,
    //}
  },
  MicroFrontEnd: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    background: "#F2F3F6",
  },
  topNavbar: {},
  // sideNavbar: {
  // [theme.breakpoints.down('md')]: {
  // display: "none"
  //}
  //}
}));

const withNavBars = (Component) => (props) => {
  const classes = useStyles({ props });
  const [state, setState] = React.useState(false);
  // var isMicroFrontEnd = sessionStorage.getItem("isMicroFrontEnd");
  const themeContext = React.useContext(ThemeContext);

  React.useEffect(() => {
    setState(JSON.parse(sessionStorage.getItem("isMicroFrontEnd")));
    setState(JSON.parse(sessionStorage.getItem("isMicroFrontEnd")));
    themeContext.setTheme({
      ...themeContext,
      name: sessionStorage.getItem("theme"),
    });
  }, [state]);

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      {!state && (
        <div className={classes.topNavbar}>
          <TopNavBar />
        </div>
      )}

      {/* Content */}
      <div className={!state ? classes.content : classes.MicroFrontEnd}>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;

//For sidebar
// <div className={classes.sideNavbar}>
//   <SideNavBar />
// </div>
